import React from "react";
import "./Home.css";
import { Row } from "antd";

import p33 from "../../assets/photos/33.jpg";

export default function Home() {
  return (
    <div id="Home">
      <h1 className="homeText">Hi, I'm Andre</h1>
    </div>
  );
}
