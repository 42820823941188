import React, {Component} from 'react';

export default class LoginUser extends Component {
    render() {
        return (
            <div id="LoginUser">
                LoginUser
            </div>
        )
    }
}