import React, { Component } from "react";
import "./DefaultLayout.css";

import HeadNav from "../../common/HeadNav";
import Footer from "../../common/FooterNav";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../../routes/Home/Home";
import About from "../../routes/About/About";
import Photo from "../../routes/Photo/Photo";

export default class DefaultLayout extends Component {
  render() {
    return (
      <Router>
        <div id="DefaultLayout">
          <div className="content-wrap">
            <HeadNav />
            <Route path="/" component={Home} exact />
            <Route path="/about" component={About} />
            <Route path="/photo" component={Photo} />
          </div>
          {/* <Footer/> */}
        </div>
      </Router>
    );
  }
}
