import React from "react";
import "./Photo.css";

import p1 from "../../assets/photos/1.jpg";
import p2 from "../../assets/photos/2.jpg";
import p3 from "../../assets/photos/3.jpg";
import p4 from "../../assets/photos/4.jpg";
import p5 from "../../assets/photos/5.jpg";
import p6 from "../../assets/photos/6.jpg";
import p7 from "../../assets/photos/7.jpg";
import p8 from "../../assets/photos/8.jpg";
import p9 from "../../assets/photos/9.jpg";
import p10 from "../../assets/photos/10.jpg";
import p11 from "../../assets/photos/11.jpg";
import p12 from "../../assets/photos/12.jpg";
import p13 from "../../assets/photos/13.jpg";
import p14 from "../../assets/photos/14.jpg";
import p15 from "../../assets/photos/15.jpg";
import p16 from "../../assets/photos/16.jpg";
import p17 from "../../assets/photos/17.jpg";
import p18 from "../../assets/photos/18.jpg";
import p19 from "../../assets/photos/19.jpg";
import p20 from "../../assets/photos/20.jpg";
import p21 from "../../assets/photos/21.jpg";
import p22 from "../../assets/photos/22.jpg";
import p23 from "../../assets/photos/23.jpg";
import p24 from "../../assets/photos/24.jpg";
import p25 from "../../assets/photos/25.jpg";
import p26 from "../../assets/photos/26.jpg";
import p27 from "../../assets/photos/27.jpg";
import p28 from "../../assets/photos/28.jpg";
import p29 from "../../assets/photos/29.jpg";
import p30 from "../../assets/photos/30.jpg";

export default function Photo() {
  return (
    <div className="Photo">
      <div className="container">
        <div className="gallery">
          <div className="gallery-item" tabIndex="0">
            <img src={p1} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p2} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p3} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p4} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p5} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p6} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p7} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p8} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p9} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p10} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p11} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p12} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p13} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p14} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p15} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p16} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p17} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p18} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p19} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p20} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p21} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p22} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p23} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p24} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p25} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p26} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p27} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p28} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p29} className="gallery-image" alt="" />
          </div>

          <div className="gallery-item" tabIndex="0">
            <img src={p30} className="gallery-image" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
