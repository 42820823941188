import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { Row } from "antd";

import "./About.css";
import resumepdf from "../../assets/Baizhou_Chen_Resume.pdf";

import pdfjs from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

export default function About() {
  // const [numPages, setNumPages] = useState(null);
  const [pageNumber /*setPageNumber*/] = useState(1);

  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  // function onDocumentLoadSuccess({ numPages }) {
  // 	setNumPages(numPages);
  // }

  function removeTextLayerOffset() {
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.top = "395px";
    });
  }

  return (
    <div id="resumeBlock">
      <Row justify="center">
        <Document
          id="resume"
          file={resumepdf}
          onLoadSuccess={removeTextLayerOffset}
        >
          <Page
            onLoadSuccess={removeTextLayerOffset}
            pageNumber={pageNumber}
            // style="display: inline-block;"
          />
        </Document>
      </Row>
    </div>
  );
}
