import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { SketchOutlined } from "@ant-design/icons";
import "./HeadNav.css";

const { Header } = Layout;

export default function HeadNav() {
  let href = window.location.href.split("/");
  //   console.log("href is:" + href);

  href = href.slice(-1)[0];
  console.log("cur is: " + href);

  const [curhref, setHref] = useState(href);

  return (
    <Header id="HeadNav">
      <div className="logo">
        <SketchOutlined />
      </div>
      <Menu
        id="menuItem"
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={curhref}
        selectedKeys={curhref}
      >
        <Menu.Item key="home" onClick={() => setHref("home")}>
          <Link to="/">Home</Link>
        </Menu.Item>
        <Menu.Item key="about" onClick={() => setHref("about")}>
          <Link to="/about">About Me</Link>
        </Menu.Item>
        <Menu.Item key="photo" onClick={() => setHref("photo")}>
          <Link to="/photo">Photography</Link>
        </Menu.Item>
      </Menu>
    </Header>
  );
}
